import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  local-class='storeknox-root {{if\n    this.isSidebarCollapsed\n    \"collapsed\"\n    \"expanded\"\n  }}'\n>\n  <TopNav\n    local-class='storeknox-header'\n    @user={{@user}}\n    @title={{if\n      this.orgIsAnEnterprise\n      (t 'appMonitoring')\n      (t 'storeknox.storeknox')\n    }}\n  />\n\n  <SideNav\n    local-class='storeknox-sidenav'\n    @isCollapsed={{this.isSidebarCollapsed}}\n    @toggleSidebar={{this.toggleSidebar}}\n    @menuItems={{this.storeknoxMenuItems}}\n    @productSwitcherFilterKey='storeknox'\n  />\n\n  {{! #id is required for scroll-to-top util }}\n  <main id='ak-dashboard-main-scroll-container' local-class='storeknox-main'>\n    {{yield}}\n  </main>\n</div>", {"contents":"<div\n  local-class='storeknox-root {{if\n    this.isSidebarCollapsed\n    \"collapsed\"\n    \"expanded\"\n  }}'\n>\n  <TopNav\n    local-class='storeknox-header'\n    @user={{@user}}\n    @title={{if\n      this.orgIsAnEnterprise\n      (t 'appMonitoring')\n      (t 'storeknox.storeknox')\n    }}\n  />\n\n  <SideNav\n    local-class='storeknox-sidenav'\n    @isCollapsed={{this.isSidebarCollapsed}}\n    @toggleSidebar={{this.toggleSidebar}}\n    @menuItems={{this.storeknoxMenuItems}}\n    @productSwitcherFilterKey='storeknox'\n  />\n\n  {{! #id is required for scroll-to-top util }}\n  <main id='ak-dashboard-main-scroll-container' local-class='storeknox-main'>\n    {{yield}}\n  </main>\n</div>","moduleName":"irene/components/storeknox-wrapper/index.hbs","parseOptions":{"srcName":"irene/components/storeknox-wrapper/index.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';

import type UserModel from 'irene/models/user';
import type { MenuItem } from '../side-nav';
import type ConfigurationService from 'irene/services/configuration';

export interface StoreknoxWrapperComponentSignature {
  Args: {
    user: UserModel;
  };
  Blocks: {
    default: [];
  };
}

export default class StoreknoxWrapperComponent extends Component<StoreknoxWrapperComponentSignature> {
  @service('browser/window') declare window: Window;
  @service declare intl: IntlService;
  @service declare configuration: ConfigurationService;

  @tracked isSidebarCollapsed: boolean;

  constructor(
    owner: unknown,
    args: StoreknoxWrapperComponentSignature['Args']
  ) {
    super(owner, args);

    const storedState = this.window.localStorage.getItem('sidebarState');

    this.isSidebarCollapsed =
      storedState !== null ? storedState === 'collapsed' : true;
  }

  get storeknoxMenuItems() {
    return [
      {
        label: this.intl.t('inventory'),
        icon: 'inventory-2',
        route: 'authenticated.storeknox.inventory.app-list',
        currentWhen:
          'authenticated.storeknox.inventory.app-list authenticated.storeknox.inventory.disabled-apps',
      },
      {
        label: this.intl.t('discovery'),
        icon: 'search',
        route: 'authenticated.storeknox.discover.result',
        currentWhen:
          'authenticated.storeknox.discover.result authenticated.storeknox.discover.requested authenticated.storeknox.discover.review',
      },
    ] as MenuItem[];
  }

  get orgIsAnEnterprise() {
    return this.configuration.serverData.enterprise;
  }

  @action
  toggleSidebar() {
    this.isSidebarCollapsed = !this.isSidebarCollapsed;

    this.window.localStorage.setItem(
      'sidebarState',
      this.isSidebarCollapsed ? 'collapsed' : 'expanded'
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    StoreknoxWrapper: typeof StoreknoxWrapperComponent;
  }
}
